/* eslint-disable @typescript-eslint/no-redeclare */
import { useEffect } from 'react';

// import { useTranslation } from "react-i18next";
// import i18next from 'i18next';

import mantracks_logo from "../../../assets/svg/mantracks_logo.svg";

import './header.css';
// import NavbarHeader from './NavbarHeader';

export const Header = ( { showOpenCloseNavBtn, setShowOpenCloseNavBtn, handlerOpenCloseNav, setHandlerOpenCloseNav } ) => {

  // const { i18n } = useTranslation([ 'common' ])

  // useEffect(() => {
  //   const i18nextLng = localStorage.getItem("i18nextLng");
  //   if (i18nextLng && i18nextLng.length > 2) {
  //     i18next.changeLanguage("en");
  //   }
  // }, [])

  // const handleLanguageChange = (event: any) => {
  //   i18n.changeLanguage(event.target.value)
  // }

  // const [ handlerOpenCloseNav, setHandlerOpenCloseNav ] = useState(true)

  const handleClick = () => {
    setHandlerOpenCloseNav(!handlerOpenCloseNav)
  }

  const handleClose = () => {
    setHandlerOpenCloseNav(false)
  }

  document.querySelector(".main-container")?.addEventListener("click", handleClose);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 950) {
        setShowOpenCloseNavBtn(true);
      } else {
        setShowOpenCloseNavBtn(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [setShowOpenCloseNavBtn]);

  return (
    <>
      <div className="header-container">
        <div className='logo-container'>
          <a href="/"><img className='mantracks-logo' src={ mantracks_logo } alt="ASBoard Logo" /></a>
        </div>
        {
          showOpenCloseNavBtn &&
          <div className='open-close-navbar' onClick={() => {handleClick()}}>
            {
              !handlerOpenCloseNav ?
              <span className="material-icons-round pending-icon">menu</span> :
              <span className="material-icons-round close-icon">close</span>
            }
          </div>

        }
        {/* <NavbarHeader/> */}
        {/* <div className='language-selector-container'>
          <select
            className='language-selector'
            onChange={handleLanguageChange}
          >
            <option value="es">Español</option>
            <option value="en">English</option>
          </select>
        </div> */}
      </div>
    </>
  )
}

export default Header
