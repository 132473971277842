import { GeneralContext } from "./GeneralContext"
import { useState } from 'react';

export const GeneralProvider = ({ children }) => {

  const [isMyAccountActive, setIsMyAccountActive] = useState(false)
  
  const handleIsMyAccountActive = () => {
    setIsMyAccountActive(true)
  }

  return (
    <GeneralContext.Provider value={{ isMyAccountActive, handleIsMyAccountActive }} >
      { children }
    </GeneralContext.Provider>
  )
}
