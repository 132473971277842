/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
// import { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { routes } from "./routes";
import classNames from 'classnames';
import { useContext } from "react";
import { GeneralContext } from "../context/GeneralContext";

import './index.css'

// import { useTranslation } from "react-i18next";

interface NavMainProps {
  handlerOpenCloseNav: boolean;
  handleClose: () => void;
}

export const NavMain = ({ handlerOpenCloseNav, handleClose }: NavMainProps) => {
  // const [currentLocation, setCurrentLocation] = useState(window.location.pathname);
  // const [isMyAccountRendered, setIsMyAccountRendered] = useState(false)
  const { isMyAccountActive } = useContext(GeneralContext)
  
  // useEffect(() => {
  //   setCurrentLocation(window.location.pathname);
  // }, []);

  // const { t } = useTranslation([ 'common' ])

  return (
    <>
      <nav className={classNames('mySidenav', { 'navbar-mobile-activa': handlerOpenCloseNav })} >
        <ul className='ul-navigation'>
          <h3 className='calculators-title'>Herramientas</h3>
          {
            !isMyAccountActive ?
              routes.filter(item => item.section === 'main').map( ({ to, name, icon, className, liClassName }) => (
                <li className={ liClassName } key={ to } onClick={() => {handleClose() }}>
                  <NavLink className="navlink" to={ to }  end >
                    <span className={`${ className } navIcon`}>{ icon }</span>
                    { name }
                  </NavLink>
                </li>
              ))
            : routes.filter(item => item.section === 'account').map( ({ to, name, icon, className, liClassName }) => (
              <li className={ liClassName } key={ to } onClick={() => {handleClose()}}>
                <NavLink className="navlink" to={ to }  end >
                  <span className={`${ className } navIcon`}>{ icon }</span>
                  { name }
                </NavLink>
              </li>
            ))
          }
        </ul>
      </nav>
    </>
  )
}
