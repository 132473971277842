import { createContext } from "react";

interface GeneralContextTypes {
  isMyAccountActive: boolean;
  handleIsMyAccountActive?: () => void;
}

const initialState: GeneralContextTypes = {
  isMyAccountActive: false
}

export const GeneralContext = createContext(initialState);