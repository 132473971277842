import { lazy, LazyExoticComponent } from 'react';


type JSXComponent = () => JSX.Element;
type Route = {
  to: string;
  path: string;
  Component: LazyExoticComponent<JSXComponent> | JSXComponent;
  name: string;
  icon?: string;
  className?: string;
  liClassName?: string;
  section: string;
}

// const Home = lazy( () => import( /* webpackChunkName: "Home" */ '../lazyload/pages/Home') );
const Ticketizador = lazy( () => import( /* webpackChunkName: "Ticketizador" */ '../lazyload/pages/main/ticketizador/Ticketizador') );
const Feedback = lazy( () => import( /* webpackChunkName: "Feedback" */ '../lazyload/pages/main/feedback/Feedback') );
const Roadmap = lazy( () => import( /* webpackChunkName: "Roadmap" */ '../lazyload/pages/main/Roadmap') );
// const Cafe = lazy( () => import( /* webpackChunkName: "Cafe" */ '../lazyload/pages/main/Cafe') );
const ShowRate = lazy( () => import( /* webpackChunkName: "ShowRate" */ '../lazyload/pages/main/showRate/ShowRate') );
const Cotizador = lazy( () => import( /* webpackChunkName: "Cotizador" */ '../lazyload/pages/main/cotizador/Cotizador') );
// const MisPresupuestos = lazy( () => import( /* webpackChunkName: "MisPresupuestos" */ '../lazyload/pages/my-account/MisPresupuestos') );
// const MiCuenta = lazy( () => import( /* webpackChunkName: "MiCuenta" */ '../lazyload/pages/my-account/MiCuenta') );
// const SignUp = lazy( () => import( /* webpackChunkName: "SignUp" */ '../lazyload/pages/SignUp' ) );
// const Login = lazy( () => import( /* webpackChunkName: "Login" */ '../lazyload/pages/Login' ) );


export const routes: Route[]= [
  // {
  //   to: '/account',
  //   path: '/account',
  //   Component: MiCuenta,
  //   name: "Guaimaral",
  //   section: 'myAccount'
  // },
  // {
  //   to: '/registro',
  //   path: '/registro',
  //   Component: SignUp,
  //   name: 'Registro',
  //   icon: 'person_add',
  //   className: 'material-icons-round',
  //   section: 'main'
  // },
  // {
  //   to: '/login',
  //   path: '/login',
  //   Component: Login,
  //   name: 'Inicia Sesion',
  //   icon: 'login',
  //   className: 'material-icons-round',
  //   section: 'main'
  // },
  {
    to: '/',
    path: '/',
    Component: Ticketizador,
    name: "Ticketizador",
    icon: 'grid_view',
    className: 'material-icons-round',
    liClassName: 'ticketizador',
    section: 'main'
  },
  {
    to: '/show-rate',
    path: '/show-rate',
    Component: ShowRate,
    name: "ShowRate",
    icon: 'calculate',
    className: 'material-icons-outlined',
    liClassName: 'calculadora-show',
    section: 'main'
  },
  {
    to: '/cotizador',
    path: '/cotizador',
    Component: Cotizador,
    name: "Cotizador",
    icon: 'sticky_note_2',
    className: 'material-icons-outlined',
    liClassName: 'cotizacion',
    section: 'main'
  },
  {
    to: '/feedback',
    path: '/feedback',
    Component: Feedback,
    name: "Sugerencias",
    icon: 'feedback',
    className: 'material-icons-outlined',
    liClassName: 'feedback',
    section: 'main'
  },
  {
    to: '/roadmap',
    path: '/roadmap',
    Component: Roadmap,
    name: "Hoja de ruta",
    icon: 'map',
    className: 'material-icons-outlined',
    liClassName: 'roadmap',
    section: 'main'
  },
  // sa
  // {
  //   to: '/registros',
  //   path: 'registros',
  //   Component: RegistroEventos,
  //   name: 'Eventos',
  //   icon: 'content_paste_search'
  // },
  // {
  //   to: '/lazyload/',
  //   path: '/lazyload/*',
  //   Component: LazyLayout,
  //   name: 'LazyLayout'
  // },
  // {
  //   to: '/no-lazy',
  //   path: 'no-lazy',
  //   Component: NoLazy,
  //   name: 'No-Lazy'
  // },
]
