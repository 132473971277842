import { NavLink } from "react-router-dom";
import { routes } from "../../../routes/routes";

import { useTranslation } from "react-i18next";

import './footer.css'

export const Footer = () => {

  const { t } = useTranslation([ 'common' ])

  const feedbackIndex = routes.findIndex( object => {
    return object.path === "/feedback";
  });

  const { name, to, icon, className } = routes[feedbackIndex]

  return (
    <>
      <footer className="footer-container">
        <div className="footer-container-left-text">
          <p>{t("footer_copyright")} <a href="https://actitudsimbiotica.com/" target="_blank" rel="noopener noreferrer">Actitud Simbiótica</a></p>
          <div className="enlaces-footer">
            <a className="aviso-legal-link" href="https://actitudsimbiotica.com/politica-de-privacidad/" target="_blank" rel="noopener noreferrer">Aviso legal</a>
          </div>
        </div>
        <div className="footer-container-feedback-btn">
          <NavLink className="navlink" to={ to } end >
            <span className={`${ className } navIcon`}>{ icon }</span>
            { t(name) }
          </NavLink>
        </div>
      </footer>
    </>
  )
}

export default Footer;
