/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./routes";
import classNames from 'classnames';

// import { useTranslation } from "react-i18next";

import { Footer } from '../lazyload/components/footer';
import { Header } from '../lazyload/components/header';
import { NavMain } from './NavMain';

export const Navigation = () => {
  const [showOpenCloseNavBtn, setShowOpenCloseNavBtn] = useState(false);
  const [handlerOpenCloseNav, setHandlerOpenCloseNav] = useState(false);


  const handleClose = () => {
    setHandlerOpenCloseNav(false)
  }

  // const { t } = useTranslation([ 'common' ])

  return (
    <Suspense fallback={ <span>Loading...</span> } >
      <BrowserRouter>
        <Header
          showOpenCloseNavBtn={showOpenCloseNavBtn}
          setShowOpenCloseNavBtn={setShowOpenCloseNavBtn}
          handlerOpenCloseNav={handlerOpenCloseNav}
          setHandlerOpenCloseNav={setHandlerOpenCloseNav}
        />
        <div className="main-layout">

          <NavMain handlerOpenCloseNav={handlerOpenCloseNav} handleClose={handleClose}/>
          <div className={classNames ('main-container', { 'main-container-background' : handlerOpenCloseNav })}>
            <Routes>
              {
                routes.map( ({ path, Component }) => (
                  <Route key={ path } path={ path } element={ <Component /> } />
                ))
              }

              <Route path="/*" element={ <Navigate to={ routes[0].to } replace /> } />
            </Routes>
          </div>
        </div>
        <Footer/>
      </BrowserRouter>

    </Suspense>
  )
}
