
import { GeneralProvider } from "./context/GeneralProvider";
import { Navigation } from "./routes/Navigation";

function App() {
  return (
    <GeneralProvider>
      <Navigation/>
    </GeneralProvider>
  );
}

export default App;
